import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/marie-kremer.jpg";
  const fullName = `Marie Kremer`;
  const zoomUrl =
    "https://us02web.zoom.us/j/83963960702?pwd=UFllUWxIMXo0ZUphSFFPbTV0bTg2dz09";
  const zoomPhoneNumber = "(646) 558-8656";
  const zoomMeetingId = "839 6396 0702";
  const zoomMeetingPasscode = "327151";
  const birthDate = new Date(1945, 5 - 1, 26);
  const deathDate = new Date(2021, 1 - 1, 1);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 23);
  const memorialTime = "2pm EST";
  const inviteText = `Due to the current restrictions we all face, in lieu of a physical ceremony, the family and friend’s of Marie Kremer invite you to a virtual service honoring her life.`;
  const obituary = `Marie Kremer, belovedmother, grandmother, sister, aunt, and wife/partner passed away on New Year’s Day in Jacksonville, Florida, after a short illness. She is survived by her husband/partner of 53 years, Frank Kremer, her daughter, Lydia Bowie, and herson and daughter-in-law, David and Gabriela Kremer. Her memory will also live on in herfive grandchildren, Isabelle, Nicholle, Hanna, Theodore, andDaniel, who were the center of her life. She is also missed by her surviving family in the Czech Republic including her sisters, Zdeňka, Ludmila and Jana and brothers Štěpán and Milan as well as well as many cousins.
  \nBorn in Opava, Czechoslovakia, on 26 May 1945 to Jan Závodný and Marie Klásková, Marie was one often siblings, including the late Jan, Anna, Jiří, and Antonín. She left Czechoslovakia in 1969 with her husband and daughter to make a new life in Michigan where she lived until 2005, when she and Frank moved to Florida to be closer to their daughter and grandchildren. Marie loved people and found an instant connection with almost anyone she met, so it was fitting for her to be a hairdresser for over 50 years with an Associate Degree in Cosmetology, from Michigan College of Beauty and Florida Community College. Most of her clients became life-long friends.
  \nPeople were drawn to her outgoing and fun personality because she often spoke what was on her mind. Marie had boundless energy and even her grandchildren had a hard time keeping up with her. Her children and grandchildren, who called her "Babi," were her greatestsource of joy but she also was a devoted and caring friend.
  \nIn retirement, Marie traveled widely, cruising almost monthly throughout the Caribbean, Australia, Africa, and South America. Sudokuwas her favorite game to play in her leisure time.
  \nShe was a bright spirit whose generosity and love were a source of joy and whose warmth and kindness brightened the lives of all who loved her and those whose lives she touched. While the world is a colder place without her presence the memory of her love is a balm for all those grieving her loss.
  \nThe family will share information about an online Celebration of Life in Czech/English and a memorial at a future date.`;
  const storiesRecordUrl = "https://stories.afterword.com/fx72vka3f";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rzm3oujqq73ubnl5lya5wrc7gwp20i7fiztic31p";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/Marie-Kremer-Recording.mp4";
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
      dropboxUrl={dropboxUrl}
    >
      <hr sx={{ marginTop: "30px", marginBottom: "30px" }} />
      <p>
        Marie Kremer, milovaná maminka, babička, sestra, teta , a manželka/
        partnerka zemřela na Nový rok v Jacksonville na Floridě , po krátké
        nemoci. Zůstal po ní manžel / partner ve věku 73 let , Frank Kremer,
        její dcera , Lydia Bowie a její syn a snacha , David a Gabriela Kremer.
        Její vzpomínka bude také živě v paměti pěti vnoučat, Isabelle, Nicholle
        , Hanna, Theodore , a Daniel, kteří byli středem jejího života. Bude
        také chybět pěti sourozencům v České republice sestrám a bratrům a také
        jejím neteřím, synovcům a dalším členům veliké rodiny a přátel.
      </p>
      <p>
        Narodila se v Opavě, v Československu, 26. května 1945 rodičům Jan
        Závodný a Marie Klásková , Marie byla jednou z deseti sourozenců , Jan,
        Anna, Jiří, Antonín, Zdeňka,Ludmila,Štěpán,Milan, Jana. Ona opustila
        Československo v roce 1969 s manželem a dcerou, a začala nový život v
        Michiganu, kde žila až do roku 2005 , kdy se ona a Frank přestěhovali na
        Floridu, aby byli blíže k jejich dceři a vnoučatům.
      </p>
      <p>
        Marie milovala lidi a našla spojení okamžitě s téměř každým, koho
        potkala, tak bylo radostí pro ni být kadeřnice po dlouhých 50 let v
        Associate v oboru Kosmetika, od Michigan College of Beauty and Florida
        Community College . Většina jejích klientů se stala celoživotními
        přáteli. Lidé byli upozorňovani na její odchozí a zábavné osobnosti ,
        protože ona často řekla co měla na mysli . Marie měla neomezenou energii
        a dokonce i její vnoučata s ní těžko držela krok. Její děti a vnuci ,
        kteří ji nazývají „Babi , “ byly jejím nejjvětším zdrojem radosti, ale
        také byla oddaná a starostlivá kamarádka.
      </p>
      <p>
        V důchodu, Marie hodně cestovala, proháněla se téměř každý měsíc po
        Karibiku , byla v Austrálii, Africe a Jižní Americe . Sudoku byla její
        oblíbená hra, kterou hrála ve svém volném čase.
      </p>
      <p>
        Byla to krásná duše jejíš štědrost a láska jsou zdrojem radosti a její
        vřelost a laskavost rozzářila životy všech, kteří ji milovali, a těm
        jejichž životů se dotkla. Zatímco svět je chladnějším místem bez její
        přítomnosti, vzpomínka na její lásku je balzámem pro všechny, kdo
        truchlí nad její ztrátou.
      </p>
    </StoryPage>
  );
};

export default ThisPage;
